<template>
  <b-card class="personal-income h-100">
    <div class="personal-income-title">
      <p>Thông tin thu nhập</p>
    </div>
    <div class="personal-income-content">
      <div class="personal-income-item">
        <p class="label">Công ty</p>
        <p>Ngân hàng quốc tế VIB</p>
      </div>
      <div class="personal-income-item">
        <p class="label">Chức vụ</p>
        <p>Trưởng nhóm</p>
      </div>
      <div class="personal-income-item">
        <p class="label">Mức thu nhập</p>
        <p>7.000.000 VNĐ</p>
      </div>
      <div class="personal-income-item">
        <p class="label">Hạn mức tín dụng</p>
        <p></p>
      </div>
      <div class="personal-income-item">
        <p class="label">Loại hợp đồng</p>
        <p>Viec.co</p>
      </div>
      <div class="personal-income-item">
        <p class="label">Thời gian công tác</p>
        <p>2 năm</p>
      </div>
      <div class="personal-income-item">
        <p class="label">Địa chỉ làm việc</p>
        <p>412 Nguyễn Thị Minh Khai, Quận 3, TP Hồ Chí Minh</p>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
};
</script>
<style lang="scss" scoped>
.personal-income {
  .personal-income-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
  .personal-income-content {
    .personal-income-item {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;
      .label {
        width: 170px;
      }
    }
  }
}
</style>
