<template>
  <div>
    <contract-view-header class="mb-1" />
    <contract-view-timeline />
    <contract-view-detail />
  </div>
</template>
<script>
import ContractViewHeader from './ContractViewHeader.vue';
import ContractViewTimeline from './ContractViewTimeline.vue';
import ContractViewDetail from './contract-view-detail/ContractViewDetail.vue';

export default {
  components: {
    ContractViewHeader,
    ContractViewTimeline,
    ContractViewDetail,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
