<template>
  <b-card class="personal-nic-image h-100">
    <div class="personal-nic-image-title">
      <p>Hình ảnh xác thực</p>
    </div>
    <swiper ref="mySwiper" class="swiper-centered-slides p-1" :options="swiperOptions">
      <swiper-slide
        v-for="data in swiperData"
        :key="data.img"
        class="rounded swiper-shadow py-1 px-3 d-flex"
      >
        <b-img :src="data.img" fluid />
      </swiper-slide>
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card>
</template>
<script>
import { BCard, BImg } from 'bootstrap-vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  components: {
    BCard,
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/elements/nic-front.jpg') },
        { img: require('@/assets/images/elements/nic-back.jpg') },
        { img: require('@/assets/images/elements/selfie.jpg') },
      ],
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.personal-nic-image {
  .personal-nic-image-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
}
</style>
