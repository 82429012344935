<template>
  <b-card class="contract-view-timeline">
    <b-row class="d-flex">
      <b-col class="text-center">
        <p>Khoản vay mới</p>
        <div class="position-relative">
          <div
            class="icon d-flex align-items-center justify-content-center rounded-circle m-auto pass"
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Thẩm định</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              current
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Tạo hạn mức</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Duyệt hạn mức</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Ký hợp đồng</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Đầu tư</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Giải ngân</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Thu nợ</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
          <div class="line"></div>
        </div>
      </b-col>
      <b-col class="text-center">
        <p>Tất toán</p>
        <div class="position-relative">
          <div
            class="
              icon
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              m-auto
              future
            "
          >
            <feather-icon icon="PhoneIcon" size="16" class="align-middle text-body" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from 'bootstrap-vue';

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.contract-view-timeline {
  .line {
    width: calc(100% - 1px);
    height: 2px;
    position: absolute;
    top: 50%;
    background-color: #000;
    left: calc(50% + 15px);
  }
  .current {
    background-color: #28c76f;
    font-weight: 700;
    svg {
      color: #fff !important;
    }
  }
  .pass {
    background-color: #979797;
    svg {
      color: #fff !important;
    }
  }
  .future {
    border: 1px solid #b9b9c3;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
  ul {
    list-style-type: none;
  }
}
</style>
