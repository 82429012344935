<template>
  <div class="contract-detail">
    <slot>
      <h2 class="text-center p-1">Thẩm định</h2>
    </slot>
    <div>
      <b-row class="mb-1">
        <b-col col="12" lg="6">
          <personal-infor />
        </b-col>
        <b-col col="12" lg="6">
          <expertise />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col col="12" lg="6">
          <referer-infor />
        </b-col>
        <b-col col="12" lg="6">
          <referer-expertise />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col col="12" lg="6">
          <income />
        </b-col>
        <b-col col="12" lg="6">
          <kalapa />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col col="12" lg="6">
          <nic-image />
        </b-col>
        <b-col col="12" lg="6">
          <bank-infor />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col col="12" lg="6">
          <contract-list />
        </b-col>
        <b-col col="12" lg="6">
          <contract-history />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from 'bootstrap-vue';
import PersonalInfor from './ContractPersonalInfor.vue';
import Expertise from './ContractExpertise.vue';
import RefererInfor from './ContractRefererInfor.vue';
import RefererExpertise from './ContractRefererExpertise.vue';
import Income from './ContractAmountIncome.vue';
import Kalapa from './ContractKalapa.vue';
import BankInfor from './ContractBankInfor.vue';
import NicImage from './ContractNicImage.vue';
import ContractList from './ContractList.vue';
import ContractHistory from './ContractHistory.vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    PersonalInfor,
    Expertise,
    RefererInfor,
    RefererExpertise,
    Income,
    Kalapa,
    BankInfor,
    NicImage,
    ContractList,
    ContractHistory,
  },
};
</script>
