<template>
  <b-card class="referer-infor h-100">
    <div class="referer-infor-title">
      <p>Thông tin tham chiếu</p>
    </div>
    <div class="referer-infor-content">
      <div class="referer-infor-item">
        <p class="label">Họ và tên</p>
        <p>Nguyễn Văn Can</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">Quan hệ</p>
        <p>Anh</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">Số điện thoại</p>
        <p>044.545.4543</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">CMND/CCCD</p>
        <p>22393123213</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">Biết khoản vay ?</p>
        <p>Không</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">Nghe nghiệp</p>
        <p>Giáo viên</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">Địa chỉ</p>
        <p>412 Nguyễn Thị Minh Khai, Quận 3, TP Hồ Chí Minh</p>
      </div>
      <div class="referer-infor-item">
        <p class="label">Ghi chú</p>
        <p></p>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
};
</script>
<style lang="scss" scoped>
.referer-infor {
  .referer-infor-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
  .referer-infor-content {
    .referer-infor-item {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;
      .label {
        width: 170px;
      }
    }
  }
}
</style>
