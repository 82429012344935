import { render, staticRenderFns } from "./ContractPersonalInfor.vue?vue&type=template&id=fbd2bef4&scoped=true&"
import script from "./ContractPersonalInfor.vue?vue&type=script&lang=js&"
export * from "./ContractPersonalInfor.vue?vue&type=script&lang=js&"
import style0 from "./ContractPersonalInfor.vue?vue&type=style&index=0&id=fbd2bef4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd2bef4",
  null
  
)

export default component.exports