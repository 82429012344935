<template>
  <b-card class="personal-bank-infor h-100">
    <div class="personal-bank-infor-title">
      <p>Tài khoản ngân hàng</p>
    </div>
    <div class="personal-bank-infor-content">
      <div class="personal-bank-infor-item">
        <p class="label">Tên chủ tài chủ</p>
        <p>NGUYEN VAN AN</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Số thẻ</p>
        <p>1233 4444 5554</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Số tài khoản</p>
        <p>223312312313</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Ngân hàng</p>
        <p>Bản Việt</p>
      </div>
      <div class="personal-bank-infor-item">
        <p class="label">Chi nhánh</p>
        <p>412 Nguyễn Thị Minh Khai, Quận 3, TP Hồ Chí Minh</p>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
};
</script>
<style lang="scss" scoped>
.personal-bank-infor {
  .personal-bank-infor-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
  .personal-bank-infor-content {
    .personal-bank-infor-item {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;
      .label {
        width: 170px;
      }
    }
  }
}
</style>
