<template>
  <b-card class="contract-list h-100">
    <div class="contract-list-title">
      <p>Danh sách khoản vay</p>
    </div>
    <b-table :fields="fields" :items="items" responsive class="mb-0">
      <template #cell(money)="data">
        {{ data.value + ' VNĐ' }}
      </template>

      <template #cell(status)="data">
        <b-badge pill :variant="data.value.variant">
          {{ data.value.value }}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BProgress, BBadge } from 'bootstrap-vue';

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
  },
  data() {
    return {
      fields: [
        { key: 'time', label: 'Thời gian' },
        // A column that needs custom formatting
        { key: 'contractId', label: 'Mã HĐ' },
        // A regular column
        { key: 'money', label: 'Số tiền vay' },
        // A virtual column made up from two fields
        { key: 'status', label: 'Trạng thái' },
      ],
      items: [
        {
          time: '19/07/2021',
          contractId: 1123,
          money: '30.000.000',
          status: { value: 'Tất toán', variant: 'success' },
        },
        {
          time: '19/07/2021',
          contractId: 1123,
          money: '30.000.000',
          status: { value: 'Trễ', variant: 'warning' },
        },
        {
          time: '19/07/2021',
          contractId: 1123,
          money: '30.000.000',
          status: { value: 'Nợ xấu', variant: 'danger' },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.contract-list {
  .contract-list-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
}
</style>
