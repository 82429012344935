<template>
  <b-card class="personal-infor h-100">
    <div class="personal-infor-title">
      <p>Thông tin cá nhân</p>
    </div>
    <div class="personal-infor-content">
      <div class="personal-infor-item">
        <p class="label">CMND/CCCD</p>
        <p>12345678</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Họ và tên</p>
        <p>Nguyễn Văn An</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Ngày sinh</p>
        <p>09/09/1999</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Số điện thoại</p>
        <p>033.666.3435</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Số điện thoại khác</p>
        <p></p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Địa chỉ</p>
        <p>412 Nguyễn Thị Minh Khai, Quận 3, TP Hồ Chí Minh</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Giới tính</p>
        <p>Nam</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Nơi cấp</p>
        <p>Đồng Nai</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Đối tác</p>
        <p>viec.co</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Địa chỉ thường trú</p>
        <p>666.64 đường 3 tháng 2 quận 10 Tp Hồ Chí Minh</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Tình trạng hôn nhân</p>
        <p>Độc thân</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Tình trạng học vấn</p>
        <p>Không biết chữ</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Facebook</p>
        <p></p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Zalo</p>
        <p>033 565 5435</p>
      </div>
      <div class="personal-infor-item">
        <p class="label">Sao kê lương</p>
        <p>File</p>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
};
</script>
<style lang="scss" scoped>
.personal-infor {
  .personal-infor-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
  .personal-infor-content {
    .personal-infor-item {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-start;
      .label {
        width: 170px;
      }
    }
  }
}
</style>
