<template>
  <b-card class="referer-expertise h-100">
    <div class="referer-expertise-title">
      <p>Thẩm định</p>
    </div>
    <b-form>
      <div class="referer-expertise-content">
        <div class="referer-expertise-item">
          <p class="label">Câu hỏi 1</p>
          <b-form-textarea id="full-name" trim placeholder="Nhập tên vai trò" />
        </div>
        <div class="referer-expertise-item">
          <p class="label">Ghi chú</p>
          <b-form-textarea id="full-name" trim placeholder="Nhập tên vai trò" />
        </div>
        <div class="text-center">
          <b-button variant="primary" class="mr-2" type="submit"> Lưu </b-button>
        </div>
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { BCard, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    BFormTextarea,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.referer-expertise {
  .referer-expertise-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
  .referer-expertise-content {
    .referer-expertise-item {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      .label {
        width: 170px;
      }
    }
  }
}
</style>
