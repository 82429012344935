<template>
  <b-card class="contract-history h-100">
    <div class="contract-history-title">
      <p>Lịch sử khoản vay</p>
    </div>
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item icon="DollarSignIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Trả nợ kì 4</h6>
          <small class="text-muted">12 min ago</small>
        </div>
        <p>Số tiền 4.000.000</p>
        <p>
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">saoke.pdf</span>
        </p>
      </app-timeline-item>

      <app-timeline-item variant="secondary" icon="UserIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Giải ngân</h6>
          <small class="text-muted">29 day ago</small>
        </div>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/12-small.png')" />
          </template>
          <h6>Vũ Viết Nhật</h6>
          <p>Ops</p>
        </b-media>
      </app-timeline-item>

      <!-- INTERVIEW SCHEDULE -->
      <app-timeline-item variant="warning" icon="MapPinIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Duyệt hạn mức</h6>
          <small class="text-muted">29 day oago</small>
        </div>
        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
          <b-media>
            <template #aside>
              <b-avatar :src="require('@/assets/images/avatars/1-small.png')" />
            </template>
            <h6>Ánh Vũ</h6>
            <span class="text-muted">Project manager</span>
          </b-media>
          <div>
            <feather-icon icon="MessageSquareIcon" class="mr-1" />
            <feather-icon icon="PhoneCallIcon" />
          </div>
        </div>
      </app-timeline-item>

      <!-- ONLINE STORE -->
      <app-timeline-item variant="danger" icon="ShoppingBagIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Tạo hồ sơ mới</h6>
          <small class="text-muted">30 day ago</small>
        </div>
        <p>Nguyễn Văn An</p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.contract-history {
  .contract-history-title {
    margin-bottom: 22px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    box-shadow: -1px 0px 2px 1px rgb(0, 0, 0, 20%);
  }
}
</style>
