<template>
  <b-card class="text-center h-100">
    <div class="h-100 d-flex justify-content-center align-items-center">
      <b-button variant="primary" class="mr-2" type="submit"> Check thông tin Kalapa </b-button>
    </div>
  </b-card>
</template>
<script>
import { BButton, BCard } from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCard,
  },
};
</script>
